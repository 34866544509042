import './App.scss';

import promptsData from './data/prompts.json';
import lifePromptsData from './data/liftetimeprompts.json';
import cosplayPromptsData from './data/cosplayprompts.json';

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

//TODO:
// reset button
// conboys version

function App() {
  const TRACKING_ID = 'G-3E2XR56XX3';
	ReactGA.initialize(TRACKING_ID);

  const [board, setBoard] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [selectedType, setSelectedType] = useState(1); // 1 - yearly, 2 - cosplay, 3 - lifetime

  //LocalStorage
  //squares: array of ints that tells what prompts go in which squares
  //complete: array of ints of square IDs that are filled in

  useEffect(() => {
    console.log('version: 0.0.3');
  }, []);

  useEffect(() => {    
    if (selectedType === 1) {
      let storageBoard = localStorage.getItem('board');
      if (storageBoard) {
        setBoard(JSON.parse(storageBoard));
      } else {
        storageBoard = generateBoard(promptsData.length);
        localStorage.setItem('board', JSON.stringify(storageBoard));
        setBoard(storageBoard);
      }
  
      let storageCompleted = localStorage.getItem('completed');
      if (storageCompleted) {
        setCompleted(JSON.parse(storageCompleted));
      } else {
        storageCompleted = [];
        localStorage.setItem('completed', JSON.stringify(storageCompleted));
        setCompleted(storageCompleted);
      }
    }

    if (selectedType === 2) {
      let storageBoard = localStorage.getItem('cos-board');
      if (storageBoard) {
        setBoard(JSON.parse(storageBoard));
      } else {
        storageBoard = generateBoard(cosplayPromptsData.length);
        localStorage.setItem('cos-board', JSON.stringify(storageBoard));
        setBoard(storageBoard);
      }
  
      let storageCompleted = localStorage.getItem('cos-completed');
      if (storageCompleted) {
        setCompleted(JSON.parse(storageCompleted));
      } else {
        storageCompleted = [];
        localStorage.setItem('cos-completed', JSON.stringify(storageCompleted));
        setCompleted(storageCompleted);
      }
    }

    if (selectedType === 3) {
      let storageBoard = localStorage.getItem('life-board');
      if (storageBoard) {
        setBoard(JSON.parse(storageBoard));
      } else {
        storageBoard = generateBoard(lifePromptsData.length);
        localStorage.setItem('life-board', JSON.stringify(storageBoard));
        setBoard(storageBoard);
      }
  
      let storageCompleted = localStorage.getItem('life-completed');
      if (storageCompleted) {
        setCompleted(JSON.parse(storageCompleted));
      } else {
        storageCompleted = [];
        localStorage.setItem('life-completed', JSON.stringify(storageCompleted));
        setCompleted(storageCompleted);
      }
    }
  }, [selectedType]);

  function generateBoard(length) {
    let indexArray = Array.from({length: length-1}, (_, i) => i + 1)
    let shuffled = shuffle(indexArray);
    let board = [];
    for (let index = 0; index < shuffled.length; index++) {
      board.push(shuffled[index]);
    }
    board.splice(12, 0, 0);
    return board;
  }

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  function toggleSquare(e) {
    let dataIndex = e.target.dataset.index;
    let targetIndex = completed.indexOf(+dataIndex);
    let newCompleted = [...completed];
    if (targetIndex > -1) {
      //remove
      newCompleted.splice(targetIndex, 1);
    } else {
      //add
      newCompleted.push(+dataIndex);
    }
    setCompleted(newCompleted);
    let key = 'completed';
    if (selectedType === 2) {
      key = 'cos-' + key;
    }
    if (selectedType === 3) {
      key = 'life-' + key;
    }
    localStorage.setItem(key, JSON.stringify(newCompleted));
  }

  function share() {
    let type = 'Yearly';
    if (selectedType === 2) {
      type = 'Cosplay';
    }
    if (selectedType === 3) {
      type = 'Lifetime';
    }
    let resultsToCopy = `DCon Bingo - ${type}\r\n\r\n`;

    for (let index = 0; index <= 24; index++) {
      let isComplete = completed.indexOf(index) > -1;

      if (isComplete) {
        resultsToCopy += "🟨";
      } else {
        resultsToCopy += "⬛";
      }

      if ((index+1) % 5 == 0) {
        resultsToCopy += "\r\n";
      }
    }
    
    resultsToCopy += "\r\nhttps://dconbingo.zachwerden.com";

    if (navigator.share) {
      navigator.share({
        text: resultsToCopy
      });
    } else {
      navigator.clipboard.writeText(resultsToCopy);
    }
  }

  function typeChange(e) {
    setSelectedType(e);
  }

  function renderSquare(index) {
    try {
      let promptIndex = board[index];
      let prompt = '';
      switch (selectedType) {
        case 1:
          prompt = promptsData[promptIndex];
          break;
        case 2:
          prompt = cosplayPromptsData[promptIndex];
          break;
        case 3:
          prompt = lifePromptsData[promptIndex];
          break;
        default:
          prompt = promptsData[promptIndex];
          break;
      } 
      let completedIndex = completed.indexOf(index);
      let className = completedIndex > -1 ? 'bcol complete' : 'bcol';
      return (
        <div className={className} id={index} data-index={index} onClick={toggleSquare}><div className='center' data-index={index}>{prompt}</div></div>
      );
    } catch {
      return (
        <div className='bcol' id={index}></div>
      );
    }
  }

  return (
    <div className="App">
      <header className="header">
        DragonCon Bingo 2023
      </header>
      <div className='typebuttons'>
        <ButtonGroup aria-label="Basic example">
          <Button variant="secondary" active={selectedType === 1} onClick={() => typeChange(1)}>Yearly</Button>
          <Button variant="secondary" active={selectedType === 2} onClick={() => typeChange(2)}>Cosplay</Button>
          <Button variant="secondary" active={selectedType === 3} onClick={() => typeChange(3)}>Lifetime</Button>
        </ButtonGroup>
      </div>
      <div className='board'>
        <div className='brow'>
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
          {renderSquare(3)}
          {renderSquare(4)}
        </div>
        <div className='brow'>
          {renderSquare(5)}
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
          {renderSquare(9)}
        </div>
        <div className='brow'>
          {renderSquare(10)}
          {renderSquare(11)}
          {renderSquare(12)}
          {renderSquare(13)}
          {renderSquare(14)}
        </div>
        <div className='brow'>
          {renderSquare(15)}
          {renderSquare(16)}
          {renderSquare(17)}
          {renderSquare(18)}
          {renderSquare(19)}
        </div>
        <div className='brow'>
          {renderSquare(20)}
          {renderSquare(21)}
          {renderSquare(22)}
          {renderSquare(23)}
          {renderSquare(24)}
        </div>
      </div>
      <div className='share'>
        <Button variant="secondary" onClick={share} >Share Progress</Button>
      </div>
      <div className='ads'>
        <div>
					Do you play Wordle?<br /><Button variant="outline-secondary" size='sm' href="https://dragoncordle.zachwerden.com" >Try DRAGONCordle</Button>
				</div>
				<div>
					Stuck in line?<br /><Button variant="outline-secondary" size='sm' href="https://dragonup.zachwerden.com" >Try DragonUp!</Button>
				</div>
      </div>
    </div>
  );
}

export default App;
